.four-points {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 16px;

  @include tablet {
    padding: 24px 40px;
  }

  @include desktop {
    height: 100vh;
    padding: 40px 40px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
    width: 100%;

    @include tablet {
      gap: 16px;
      flex-flow: row wrap;
    }

    @include desktop {
      gap: 40px;
      flex-flow: row wrap;
    }
  }

  &__point {
    border: solid 2px $accent-blue;
    border-radius: 2px;
    padding: 24px;

    @include tablet {
      flex-basis: 45%;
      padding: 24px;
    }

    @include desktop {
      flex-basis: 45%;
      padding: 40px 40px;
    }

    &-top {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 32px;
    }

    &-title {
      @include subheading-small;
    }

    &-icon {
      height: 50px;

      @include tablet {
        height: 60px;
      }

      @include desktop {
        height: 90px;
      }
    }

    &-text {
      @include desktop {
        font-size: 20px;
      }
    }

    &--alt-border {
      border-color: $accent-light;
    }

    &:first-child {
      border-color: $accent-light;
    }

    &:nth-child(3) {
      border-color: $accent-light;

      @include tablet {
        border-color: $accent-blue;
      }

      @include desktop {
        border-color: $accent-blue;
      }
    }

    &:nth-child(4) {
      border-color: $accent-blue;

      @include tablet {
        border-color: $accent-light;
      }

      @include desktop {
        border-color: $accent-light;
      }
    }
  }
}
