.header {
  position: absolute;
  top: 0;
  z-index: 4;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 1700px;
  width: 100vw;
  padding: 16px;

  @include tablet {
    padding: 24px;
  }

  @include desktop {
    padding: 24px 40px;
  }

  &__logo {
    padding: 8px;
    background-color: rgb(181, 183, 190, 0.15);
    backdrop-filter: blur(20px);

    &-img {
      width: 24vw;

      @include tablet {
        width: 15vw;
        min-width: 80px;
      }
      @include desktop {
        height: 12.5vh;
        min-height: 80px;
        max-height: 120px;
        width: auto;
      }
    }
  }

  &__button {
    @include button;
    height: 45px;
    width: 161px;
    background-color: rgb(181, 183, 190, 0.15);
    backdrop-filter: blur(20px);
    text-decoration: none;
    border: solid 1px rgb(181, 183, 190, 0.15);

    @include desktop {
      height: 70px;
      width: 240px;
      &:hover {
        border: solid 1px $accent-green;
      }
    }
  }
}
