.contact {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 16px;
  background-color: $background-secondary;

  @include tablet {
    padding: 24px 40px;
  }

  @include desktop {
    height: 100vh;
    padding: 40px 40px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
  }

  &__container {
    display: flex;
    flex-direction: column;
    //flex: 1;
    justify-content: space-between;
    gap: 40px;
    width: 100%;

    @include desktop {
      flex-direction: row;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    @include desktop {
      width: 40%;
    }
  }
  &__form {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    align-content: center;

    &-wrapper {
      @include desktop {
        width: 50%;
      }
    }

    &-input {
      width: 100%;
      height: 50px;
      padding-left: 16px;
      border-radius: 0;
      align-self: center;
      border: none;
      box-shadow: none;
      background-color: rgb(8, 129, 175, 0.15);
    }

    &-message {
      width: 100%;
      height: 300px;
      padding-left: 16px;
      border-radius: 0;
      align-self: center;
      padding-top: 10px;
      border: none;
      box-shadow: none;

      @include desktop {
        height: 450px;
        max-height: 37vh;
      }
    }

    &-button {
      @include body-small;
      height: 45px;
      width: 161px;
      background: none;
      text-decoration: none;
      border: solid 2px $accent-blue;
      align-self: flex-end;
      font-size: 18px;

      &:hover {
        background-color: $accent-blue;
      }
    }
  }
}

input[type="text"],
input[type="email"],
textarea {
  background-color: rgb(8, 129, 175, 0.15);
  color: $text-primary;
  font-family: poppins;

  &::placeholder {
    color: white;
  }
  ::-ms-input-placeholder {
    color: white;
  }
}
