.hero {
  overflow: hidden;
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;

  &__video {
    display: none;
    @include desktop {
      position: absolute;
      z-index: 2;
      display: block;
      right: 10;
      left: auto;
      transform: none;
      min-height: 110vh;
      object-fit: cover;
      object-position: center;
      overflow: hidden;
    }
  }

  &__image {
    display: block;
    position: absolute;
    z-index: 2;
    left: 50%;
    transform: translateX(-50%);
    height: 100vh;
    object-fit: cover;
    object-position: center;
    overflow: hidden;

    @include desktop {
      display: none;
    }
  }

  &__overlay {
    position: absolute;
    z-index: 3;
    background-color: rgb(34, 32, 32, 0.3);
    height: 100%;
    width: 100%;
  }

  &__content {
    @include hero;
    position: absolute;
    z-index: 4;
    padding: 24px;
    margin: 16px;
    background-color: rgb(181, 183, 190, 0.15);
    backdrop-filter: blur(20px);

    &--bold {
      color: $text-accent;
      //text-decoration: underline;
    }

    @include tablet {
      align-self: center;
      padding: 40px;
      margin: 24px;
    }

    @include desktop {
      width: 50%;
      min-width: fit-content;
      padding: 40px;
      margin: 40px;
    }

    @include desktop-short {
      top: 15vh;
    }
  }

  &__button {
    position: absolute;
    display: flex;
    justify-content: center;
    z-index: 4;
    bottom: 0;
    width: 100%;
    margin-bottom: 24px;

    &-inner {
      background: none;
      border: none;
    }

    &-icon {
      width: 24px;

      @include tablet {
        width: 36px;
      }

      @include desktop {
        width: 40px;
      }
    }
  }
}
