.intro {
  padding: 32px 16px 20px 16px;

  @include tablet {
    padding: 48px 40px 24px 40px;
  }

  @include desktop {
    padding: 40px 40px;
  }

  &__content {
    max-width: 800px;
  }
}
