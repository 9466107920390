@import "./partials/_partials";
@import "./sections/_sections";
@import "./components/_components";

* {
  box-sizing: border-box;
}
body {
  margin: 0;
  background-color: $background-primary;
  overflow: scroll;
}

h1 {
  @include section-heading;
}

h2,
h3,
h4,
h5 {
  font-family: poppins;
  color: $text-primary;
  margin: 0;
}

p {
  @include body;
}

a {
  @include link;
}

textarea {
  resize: none;
}

.scroll {
  height: 100vh;
  overflow: auto;
  scroll-snap-type: y mandatory;
}

.slide {
  @include desktop {
    position: relative;
    width: 100%;
    scroll-snap-align: start;
    overflow-y: scroll;
  }
}

.App {
  background-color: $background-primary;
  max-width: 1700px;
  margin: auto;
  position: relative;
  z-index: 1;
}
