.footer {
  display: flex;
  flex-direction: column;
  padding: 32px 32px;

  @include desktop {
    flex-direction: row;
    justify-content: space-between;
    padding: 32px 60px;
  }

  &__section {
    display: flex;
    flex-direction: column;
  }

  &__policy {
    @include footer;
    background: none;
    border: none;
    text-decoration: underline;

    &:hover {
      color: $accent-blue;
    }
  }

  &__text {
    @include footer;
  }

  &__link {
    color: $text-primary;
    font-size: 16px;

    &:hover {
      color: $accent-blue;
    }
  }
}
