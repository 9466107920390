.backToTop {
  position: fixed;
  right: 16px;
  bottom: 40px;
  z-index: 3;
  border: none;
  background: none;

  @include desktop {
    right: 40px;
    bottom: 40px;
  }
}
