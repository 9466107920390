@import "./breakpoints";
@import "./colours";

@font-face {
  font-family: poppins;
  src: url("../../assets/fonts/poppins/poppins-regular.woff");
  font-weight: 400;
}

@font-face {
  font-family: poppins;
  src: url("../../assets/fonts/poppins/poppins-medium.woff");
  font-weight: 500;
}

@font-face {
  font-family: poppins;
  src: url("../../assets/fonts/poppins/poppins-semibold.woff");
  font-weight: 600;
}

@font-face {
  font-family: poppins;
  src: url("../../assets/fonts/poppins/poppins-bold.woff");
  font-weight: 700;
}

@mixin hero {
  font-family: poppins;
  font-weight: 500;
  color: $text-primary;
  font-size: 6vw;

  @include tablet {
    font-size: 42px;
  }

  @include desktop {
    font-size: 64px;
  }
}

@mixin section-heading {
  font-family: poppins;
  font-weight: 600;
  color: $text-primary;
  font-size: 24px;

  @include tablet {
    font-size: 32px;
  }

  @include desktop {
    font-size: 48px;
  }
}

@mixin body {
  font-family: poppins;
  font-weight: 400;
  color: $text-primary;
  font-size: 16px;

  @include tablet {
  }

  @include desktop {
    font-size: 24px;
  }
}

@mixin body-small {
  font-family: poppins;
  font-weight: 400;
  color: $text-primary;
  font-size: 16px;

  @include tablet {
  }

  @include desktop {
    font-size: 20px;
  }
}

@mixin link {
  font-family: poppins;
  font-weight: 500;
  color: $accent-blue;
  font-size: 16px;

  @include tablet {
  }

  @include desktop {
    font-size: 24px;
  }
}

@mixin subheading-medium {
  font-family: poppins;
  font-weight: 500;
  color: $text-primary;
  font-size: 16px;

  @include tablet {
    font-size: 24px;
  }

  @include desktop {
    font-size: 24px;
  }
}

@mixin subheading-bold {
  font-family: poppins;
  font-weight: 700;
  color: $text-primary;
  font-size: 16px;

  @include tablet {
    font-size: 24px;
  }

  @include desktop {
    font-size: 24px;
  }
}

@mixin subheading-small {
  font-family: poppins;
  font-weight: 700;
  color: $text-primary;
  font-size: 18px;

  @include tablet {
    font-size: 20px;
  }

  @include desktop {
    font-size: 24px;
  }
}

@mixin button {
  font-family: poppins;
  font-weight: 500;
  color: $text-primary;
  font-size: 16px;

  @include tablet {
  }

  @include desktop {
    font-size: 24px;
  }
}

@mixin footer {
  font-family: poppins;
  font-weight: 400;
  color: $text-primary;
  font-size: 16px;
}

.highlight {
  color: $text-accent;
}

.underline {
  text-decoration: underline;
  text-underline-offset: 8px;
  text-decoration-color: $accent-green;
}
