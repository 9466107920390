$background-primary: #000815;
$background-secondary: #010101;

$text-primary: #fcfefe;
$text-accent: #48cdff;

$accent-blue: #00adef;
$accent-light: #b8ebff;
$accent-green: #1dc539;
$accent-orange: #f19036;
