.three-points {
  width: 100%;
  padding: 20px 0;
  background-color: $background-secondary;

  @include tablet {
    padding: 24px 0;
  }

  @include desktop {
    height: 100vh;
    padding: 40px 0;
  }

  &__title {
    padding-left: 16px;

    @include tablet {
      padding-left: 40px;
    }

    @include desktop {
      padding-left: 40px;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    padding: 16px;

    @include tablet {
      padding: 0;
    }

    @include desktop {
      padding: 0;
    }
  }

  &__point {
    display: flex;
    flex-direction: row;
    align-items: center;

    &:nth-child(2) {
      flex-direction: row-reverse;
      width: 100%;
    }

    @include tablet {
      gap: 24px;
    }

    @include desktop {
      gap: 24px;
    }

    &-content {
      width: auto;

      @include tablet {
        width: 65%;
      }

      @include desktop {
        width: 70%;
      }
    }

    &-top {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 12px;

      @include tablet {
        gap: 24px;
      }

      @include desktop {
        gap: 24px;
      }
    }

    &-title {
      @include subheading-small;
    }

    &-icon {
      height: 30px;
      width: 30px;

      @include tablet {
        height: 50px;
        width: 50px;
      }

      @include desktop {
        height: 50px;
        width: 50px;
      }
    }
  }

  &__line {
    width: 100px;
    height: 3px;
    background-color: $accent-blue;
    display: none;

    @include tablet {
      display: flex;
      width: 72px;
    }

    @include desktop {
      display: flex;
    }
  }
}
