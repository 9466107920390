$mobile-max-width: 767px;
$tablet-min-width: 768px;
$tablet-max-width: 1279px;
$desktop-min-width: 1280px;

@mixin tablet {
  @media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (min-width: $desktop-min-width) {
    @content;
  }
}

@mixin desktop-short {
  @media only screen and (max-height: 900px) and (min-width: $desktop-min-width) {
    @content;
  }
}
