.policy {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: rgb(181, 183, 190, 0.15);
  backdrop-filter: blur(20px);

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    align-self: flex-end;
    background: none;
    border: none;
    margin-bottom: -50px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 90%;
    height: 90vh;
    overflow: scroll;
    padding: 40px;
    background-color: $background-secondary;

    @include tablet {
    }
    @include desktop {
      width: 60%;
      height: 90vh;
    }
  }

  &__text {
    font-size: 16px;
  }

  &__subheading {
    padding-top: 18px;
    text-decoration: underline;
  }

  &__close {
    color: $accent-blue;
    font-size: 18px;
    text-decoration: underline;
  }
}
